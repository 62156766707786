import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";

function MarketplaceOnboarding() {
  const queryString = window.location.pathname.split("/").join("");
  console.log("queryString", queryString);
  return (
    <>
      <SrrHeader
        heading="Marketplace Onboarding Questionnaire"
        breadcrumb_path1="CENTER OF EXCELLENCE"
        breadcrumb_path2="MARKETING ONBOARDING QUESTIONNAIRE"
      />

      <section className="form-section my-3 text-justify">
        <ImportScript
          src={process.env.REACT_APP_FORDDIRECT_COE_MARKETING_ONBOARDING}
          pagId={process.env.REACT_APP_FORDDIRECT_COE_MARKETING_ONBOARDING_PGID}
          queryString={queryString}
        />
      </section>
    </>
  );
}

export default MarketplaceOnboarding;
