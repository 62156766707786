import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";

function TSP() {
  const queryString = window.location.pathname.split("/").join("");
  console.log("queryString", queryString);

  return (
    <>
      <SrrHeader
        heading="Technology, Security and Privacy"
        breadcrumb_path1="CENTER OF EXCELLENCE"
        breadcrumb_path2="TECHNOLOGY, SECURITY AND PRIVACY"
      />

      <section className="form-section my-3 text-justify">
        <ImportScript
          src={process.env.REACT_APP_FORDDIRECT_COE_TSP}
          pagId={process.env.REACT_APP_FORDDIRECT_COE_TSP_PGID}
          queryString={queryString}
        />
      </section>
    </>
  );
}

export default TSP;
